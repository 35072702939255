import React from "react";

const Tags = ({setColor}) => {
  return (
    <div className="mt-4 mb-10 grid gap-2 px-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
      <span
        className="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300 cursor-pointer"
        onClick={() =>
          setColor({
            red: 0.147,
            green: 0.197,
            blue: 0.253,
          })
        }
      >
        Blue
      </span>
      <span
        className="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-gray-700 dark:text-gray-300 cursor-pointer"
        onClick={() =>
          setColor({
            red: 0.209,
            green: 0.213,
            blue: 0.219,
          })
        }
      >
        Dark
      </span>
      <span
        className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300 cursor-pointer"
        onClick={() =>
          setColor({
            red: 0.252,
            green: 0.165,
            blue: 0.165,
          })
        }
      >
        Red
      </span>
      <span
        className="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300 cursor-pointer"
        onClick={() =>
          setColor({
            red: 0.134,
            green: 0.239,
            blue: 0.172,
          })
        }
      >
        Green
      </span>
      <span
        className="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-yellow-900 dark:text-yellow-300 cursor-pointer"
        onClick={() =>
          setColor({
            red: 0.113,
            green: 0.63,
            blue: 0.18,
          })
        }
      >
        Yellow
      </span>
      <span
        className="bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-indigo-900 dark:text-indigo-300 cursor-pointer"
        onClick={() =>
          setColor({
            red: 0.165,
            green: 0.18,
            blue: 0.252,
          })
        }
      >
        Indigo
      </span>
      <span
        className="bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-purple-900 dark:text-purple-300 cursor-pointer"
        onClick={() =>
          setColor({
            red: 0.216,
            green: 0.18,
            blue: 0.254,
          })
        }
      >
        Purple
      </span>
      <span
        className="bg-pink-100 text-pink-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-pink-900 dark:text-pink-300 cursor-pointer"
        onClick={() =>
          setColor({
            red: 0.249,
            green: 0.213,
            blue: 0.219,
          })
        }
      >
        Pink
      </span>
    </div>
  );
};

export default Tags;
