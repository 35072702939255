import React, { useEffect, useRef } from "react";
import * as BABYLON from "babylonjs";
import "babylonjs-loaders";
import "./index.css";

const BabylonScene = ({
  folderPath = "/",
  fileName = "mug-test-3.gltf",
  color = null,
  position = "top",
  title = "-",
  text = null,
  isFromUpload = false,
  urlFile = null,
}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const engine = new BABYLON.Engine(canvas, true);

    const createScene = async () => {
      // Init scene
      const scene = new BABYLON.Scene(engine);
      scene.clearColor = new BABYLON.Color4(229, 231, 235, 0.2);

      if (position === "top") {
        const camera = new BABYLON.UniversalCamera(
          "Camera",
          new BABYLON.Vector3(2, 11, 3),
          scene
        );
        camera.setTarget(BABYLON.Vector3.Zero());
      }

      if (position === "bottom") {
        const camera = new BABYLON.UniversalCamera(
          "Camera",
          new BABYLON.Vector3(0, -8, 0),
          scene
        );
        camera.setTarget(BABYLON.Vector3.Zero());
      }

      if (position === "front") {
        const camera = new BABYLON.UniversalCamera(
          "Camera",
          new BABYLON.Vector3(13, 7, 1),
          scene
        );
        camera.setTarget(BABYLON.Vector3.Zero());
      }

      if (position === "free") {
        const camera = new BABYLON.ArcRotateCamera(
          "camera",
          -Math.PI / 3,
          Math.PI / 2.5,
          13,
          new BABYLON.Vector3(0, 1, 0)
        );

        camera.lowerBetaLimit = 0;
        camera.upperBetaLimit = Math.PI / 2.3;

        camera.lowerAlphaLimit = -Math.PI;
        camera.upperAlphaLimit = Math.PI;

        camera.upperRadiusLimit = 15;
        camera.lowerRadiusLimit = 2;
        camera.attachControl(canvasRef.current, true);
        scene.activeCamera = camera;
      }

      // HemispericLight
      new BABYLON.HemisphericLight(
        "light",
        new BABYLON.Vector3(0, 1, 0),
        scene
      );

      // Directional Light
      const directional = new BABYLON.DirectionalLight(
        "downLight",
        new BABYLON.Vector3(1, 1, 1),
        scene
      );
      directional.intensity = 0.5;

      // Import File
      const gltf = await BABYLON.SceneLoader.ImportMeshAsync(
        "",
        folderPath,
        fileName,
        scene
      );

      // Adding Color To Mug
      if (color !== null) {
        gltf.meshes.forEach((mesh) => {
          const material = new BABYLON.StandardMaterial("material", scene);
          material.diffuseColor = new BABYLON.Color3(
            color.red,
            color.green,
            color.blue
          );
          mesh.material = material;
        });
      }

      if (text !== null) {
        const meshes = gltf.meshes[4];
        const textTexture = createTextTexture(text, scene, color);
        const textMaterial = new BABYLON.StandardMaterial(
          "textMaterial",
          scene
        );
        textMaterial.diffuseTexture = textTexture;
        meshes.material = textMaterial;
      }

      if (urlFile !== null) {
        const meshes = gltf.meshes[3];
        const material = createImageMaterial(scene, urlFile);
        meshes.material = material;
      }

      // Render GLTF File
      engine.runRenderLoop(() => {
        scene.render();
      });
    };

    createScene();

    return () => {
      engine.dispose();
    };
  }, [fileName, folderPath, color, text, urlFile]);

  const createTextTexture = (text, scene, color) => {
    const textureWidth = 1300;
    const textureHeight = 400;
    const dynamicTexture = new BABYLON.DynamicTexture(
      "textTexture",
      { width: textureWidth, height: textureHeight },
      scene
    );
    const context = dynamicTexture.getContext();

    if (color !== null) {
      context.fillStyle = `rgba(${removeZeroFromDecimal(
        color.red
      )}, ${removeZeroFromDecimal(color.green)}, ${removeZeroFromDecimal(
        color.blue
      )}, 1)`;
      context.fillRect(0, 0, textureWidth, textureHeight);
    }

    context.fillStyle = "white";
    context.font = "bold 48px Arial";
    context.textAlign = "center";

    context.scale(-1, 1);
    context.rotate((180 * Math.PI) / 180);
    context.fillText(text, 310, -300, 200);
    context.restore();

    // Update dynamic texture
    dynamicTexture.update();

    return dynamicTexture;
  };

  function removeZeroFromDecimal(number) {
    // Convert number to string
    let stringNumber = number.toString();

    // Replace '0.' with ''
    let result = stringNumber.replace(/^0\./, "");

    return result;
  }

  const createImageMaterial = (scene, imageUrl) => {
    const texture = new BABYLON.Texture(
      imageUrl,
      scene,
      false,
      true,
      BABYLON.Texture.NEAREST_SAMPLINGMODE
    );

    const material = new BABYLON.StandardMaterial("imageMaterial", scene);
    material.diffuseTexture = texture;
    texture.uScale = 2; // Faktor skala horizontal
    texture.vScale = 2;
    return material;
  };

  const isValidURL = (url) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );

    return pattern.test(url);
  };

  return (
    <div>
      <h1 className="font-semibold text-2xl text-center my-2">{title}</h1>
      <div className="p-4 bg-gray-200 flex items-center justify-center">
        <canvas ref={canvasRef} />
      </div>
    </div>
  );
};

export default BabylonScene;
