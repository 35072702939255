import React, { useState } from "react";
import "./App.css";
import { BabylonScene, Tags } from "./components";
import axios from "axios";

const App = () => {
  const [color, setColor] = useState(null);
  const [tempText, setTempText] = useState(null);
  const [text, setText] = useState(null);

  const [selectedFile, setSelectedFile] = useState(null);
  const [urlFile, setUrlFile] = useState(null);
  const [isFromUpload, setIsFromUpload] = useState(false);

  const handleFileChange = (e) => {
    console.log(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
  };

  const submitForm = () => {
    setIsFromUpload(false);
    if (selectedFile !== null) {
      handleUpload();
    } else if (tempText) {
      setText(tempText);
    }
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', selectedFile);

    const base_url = 'https://photobooth.mediavisi.com';
    // const base_url = 'http://localhost:3030';

    try {
      const response = await axios.post(`${base_url}/open-api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setIsFromUpload(true);
        setUrlFile(response.data.url);
      }

      setSelectedFile(null);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <main>
      <div className="container mx-auto">
        <h1 className="font-semibold text-2xl text-center mt-6">
          Select Color
        </h1>
        <Tags setColor={setColor} />
        <div className="my-4 px-32 mt-2">
          <div className="mb-6">
            <label
              className="block mb-2 text-sm font-medium text-gray-500"
              htmlFor="sablon"
            >
              Text Sablon
            </label>
            <input
              type="text"
              id="sablon"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder="Masukkan Teks Sablon"
              onChange={(val) => setTempText(val.target.value)}
              required
            />
            <div className="mt-3">
              <label
                className="block mb-2 text-sm font-medium text-gray-500"
                htmlFor="file_input"
              >
                Upload file
              </label>
              <input
                className="block w-full text-sm text-gray-400 border border-gray-600 rounded-lg cursor-pointer bg-gray-700 placeholder-gray-400 focus:outline-none"
                aria-describedby="file_input_help"
                id="file_input"
                type="file"
                onChange={handleFileChange}
              />
              <p className="mt-1 text-sm text-gray-500" id="file_input_help">
                SVG, PNG, JPG or GIF (MAX. 800x400px).
              </p>
            </div>
          </div>
          <button
            type="button"
            onClick={() => submitForm()}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
          >
            Terapkan
          </button>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 mb-10">
          <BabylonScene
            color={color}
            position="free"
            title="Free Camera"
            text={text}
            isFromUpload={isFromUpload}
            urlFile={urlFile}
          />
          <BabylonScene
            color={color}
            position="top"
            title="POV Atas"
            text={text}
            isFromUpload={isFromUpload}
            urlFile={urlFile}
          />
          <BabylonScene
            color={color}
            position="front"
            title="POV Depan"
            text={text}
            isFromUpload={isFromUpload}
            urlFile={urlFile}
          />
          <BabylonScene
            color={color}
            position="bottom"
            title="POV Bawah"
            text={text}
            isFromUpload={isFromUpload}
            urlFile={urlFile}
          />
        </div>
      </div>
    </main>
  );
};

export default App;
